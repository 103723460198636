<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="取件人"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              placeholder="请输入收件人"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="电话"
            label-for="phonecode"
          >
            <v-select
              id="phonecode"
              v-model="form.phonecode"
              class="pick-select"
              placeholder="请选择国号"
              :options="phonecodeList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :selectable="option => !option.value.includes('select_value')"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label=" "
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="form.phone"
              style="margin-top:8px;"
              placeholder="请输入电话号码"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="运单信息"
    >
      <b-row class="mb-2">
        <b-col md="6">
          <span class="parcel-info">运单ID CNSPM023434</span>
        </b-col>
        <b-col md="6">
          <span class="parcel-info">包裹数量 3</span>
        </b-col>
      </b-row>
      <b-table
        :items="items"
        :fields="fields"
        responsive
        class="mb-3"
      >
        <template #cell(no)="data">
          <span>{{ data.index + 1 }}</span>
        </template>
      </b-table>
    </b-card>
    <b-card
      title="取货凭证"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="取件人"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              placeholder="请输入取件人"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="电话"
            label-for="phonecode"
          >
            <v-select
              id="phonecode"
              v-model="form.phonecode"
              class="pick-select"
              placeholder="请选择国号"
              :options="phonecodeList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :selectable="option => !option.value.includes('select_value')"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label=" "
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="form.phone"
              style="margin-top:8px;"
              placeholder="请输入电话号码"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <div class="d-flex justify-content-center mt-4">
      <b-button variant="relief-primary">
        确认取件
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BTable, BButton,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BRow, BCol, BFormGroup, BFormInput, vSelect, BTable, BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        name: '李四',
        phonecode: { value: '61', text: '61' },
        phone: '121334444',
      },
      phonecodeList: [
        { value: 'select_value', text: 'Select Value' },
        { value: '61', text: '61' },
      ],
      items: [{
        weight: 10,
      }, {
        weight: 20,
      }],
      fields: [{
        key: 'no',
        label: 'No.',
      }, {
        key: 'weight',
        label: '实际重量(kg)',
      }, {
        key: 'length',
        label: '长(cm)',
      }, {
        key: 'width',
        label: '宽(cm)',
      }, {
        key: 'height',
        label: '高(cm)',
      }],
    }
  },
}
</script>

<style lang="scss">
label{
  min-height: 20px;
}
.pick-select .vs__dropdown-toggle{
  height:39px;
}
</style>
